import React from 'react';

import Layout from '../components/layout';

import './pages.css';

import BookCover from '../images/books/weapons-of-math-destruction.png';

import { graphql } from 'gatsby';

function Update({ data }) {
  const goodreadsWidget = `
    <style type="text/css" media="screen">
        .gr_grid_container {
            /* customize grid container div here. eg: width: 500px; */
        }

        .gr_grid_book_container {
            /* customize book cover container div here */
            float: left;
            width: 98px;
            height: 160px;
            padding: 0px 0px;
            overflow: hidden;
        }
    </style>
    <div id="gr_grid_widget_1716234150">
        <!-- Show static html as a placeholder in case js is not enabled - javascript include will override this if things work -->
            <h2>
                <a style="text-decoration: none;" rel="nofollow" href="https://www.goodreads.com/review/list/106397951-erin-xue?shelf=read&utm_medium=api&utm_source=grid_widget">My recent favs</a>
            </h2>
            <div class="gr_grid_container">
            <div class="gr_grid_book_container"><a title="Poor Charlie’s Almanack: The Essential Wit and Wisdom of Charles T. Munger" rel="nofollow" href="https://www.goodreads.com/book/show/157686100-poor-charlie-s-almanack"><img alt="Poor Charlie’s Almanack: The Essential Wit and Wisdom of Charles T. Munger" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1701791542l/157686100._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="米拉蒂" rel="nofollow" href="https://www.goodreads.com/book/show/179254296"><img alt="米拉蒂" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1688000002l/179254296._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Biology and Human Behavior: The Neurological Origins of Individuality" rel="nofollow" href="https://www.goodreads.com/book/show/2506042.Biology_and_Human_Behavior"><img alt="Biology and Human Behavior: The Neurological Origins of Individuality" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1437947459l/2506042._SX98_.jpg" /></a></div>

            <div class="gr_grid_book_container"><a title="Brief Answers to the Big Questions" rel="nofollow" href="https://www.goodreads.com/book/show/54083621-brief-answers-to-the-big-questions"><img alt="Brief Answers to the Big Questions" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1592228544l/54083621._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Inner Game of Tennis: The Classic Guide to the Mental Side of Peak Performance" rel="nofollow" href="https://www.goodreads.com/book/show/905.The_Inner_Game_of_Tennis"><img alt="The Inner Game of Tennis: The Classic Guide to the Mental Side of Peak Performance" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1427665815l/905._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Poor Economics: A Radical Rethinking of the Way to Fight Global Poverty" rel="nofollow" href="https://www.goodreads.com/book/show/10245602-poor-economics"><img alt="Poor Economics: A Radical Rethinking of the Way to Fight Global Poverty" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1344679036l/10245602._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Build: An Unorthodox Guide to Making Things Worth Making" rel="nofollow" href="https://www.goodreads.com/book/show/59696349-build"><img alt="Build: An Unorthodox Guide to Making Things Worth Making" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1652120231l/59696349._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Art of Doing Science and Engineering: Learning to Learn" rel="nofollow" href="https://www.goodreads.com/book/show/530415.The_Art_of_Doing_Science_and_Engineering"><img alt="The Art of Doing Science and Engineering: Learning to Learn" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1175565523l/530415._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Hardware Hacker: Adventures in Making and Breaking Hardware" rel="nofollow" href="https://www.goodreads.com/book/show/30804383-the-hardware-hacker"><img alt="The Hardware Hacker: Adventures in Making and Breaking Hardware" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1484897199l/30804383._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Chip War: The Fight for the World's Most Critical Technology" rel="nofollow" href="https://www.goodreads.com/book/show/60321447-chip-war"><img alt="Chip War: The Fight for the World's Most Critical Technology" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1662566820l/60321447._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Human Universals" rel="nofollow" href="https://www.goodreads.com/book/show/1080960.Human_Universals"><img alt="Human Universals" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1387663342l/1080960._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Amp It Up: Leading for Hypergrowth by Raising Expectations, Increasing Urgency, and Elevating Intensity" rel="nofollow" href="https://www.goodreads.com/book/show/59383944-amp-it-up"><img alt="Amp It Up: Leading for Hypergrowth by Raising Expectations, Increasing Urgency, and Elevating Intensity" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1642654744l/59383944._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Cold Start Problem: How to Start and Scale Network Effects" rel="nofollow" href="https://www.goodreads.com/book/show/55338968-the-cold-start-problem"><img alt="The Cold Start Problem: How to Start and Scale Network Effects" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1627895516l/55338968._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Mom Test: How to talk to customers & learn if your business is a good idea when everyone is lying to you" rel="nofollow" href="https://www.goodreads.com/book/show/52283963-the-mom-test"><img alt="The Mom Test: How to talk to customers & learn if your business is a good idea when everyone is lying to you" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1566473431l/52283963._SX98_SY160_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Eleanor Oliphant Is Completely Fine" rel="nofollow" href="https://www.goodreads.com/book/show/31434883-eleanor-oliphant-is-completely-fine"><img alt="Eleanor Oliphant Is Completely Fine" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1493724347l/31434883._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Book of Eels: Our Enduring Fascination with the Most Mysterious Creature in the Natural World" rel="nofollow" href="https://www.goodreads.com/book/show/48737315-the-book-of-eels"><img alt="The Book of Eels: Our Enduring Fascination with the Most Mysterious Creature in the Natural World" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1573284693l/48737315._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="A Crack In Creation: Gene Editing and the Unthinkable Power to Control Evolution" rel="nofollow" href="https://www.goodreads.com/book/show/33389769-a-crack-in-creation"><img alt="A Crack In Creation: Gene Editing and the Unthinkable Power to Control Evolution" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1481773208l/33389769._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="Speculative Everything: Design, Fiction, and Social Dreaming (The MIT Press)" rel="nofollow" href="https://www.goodreads.com/book/show/19859125-speculative-everything"><img alt="Speculative Everything: Design, Fiction, and Social Dreaming" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1387748114l/19859125._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Intelligent Investor" rel="nofollow" href="https://www.goodreads.com/book/show/39704483-the-intelligent-investor"><img alt="The Intelligent Investor" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1522687173l/39704483._SX98_.jpg" /></a></div>
=            <div class="gr_grid_book_container"><a title="Architecture: Form, Space, & Order" rel="nofollow" href="https://www.goodreads.com/book/show/21552381-architecture"><img alt="Architecture: Form, Space, & Order" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1407711858l/21552381._SX98_.jpg" /></a></div>
            <div class="gr_grid_book_container"><a title="The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers" rel="nofollow" href="https://www.goodreads.com/book/show/20657434-the-hard-thing-about-hard-things"><img alt="The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers" border="0" src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391109084l/20657434._SX98_.jpg" /></a></div>
            <noscript><br/>Share <a rel="nofollow" href="/">book reviews</a> and ratings with Erin, and even join a <a rel="nofollow" href="/group">book club</a> on Goodreads.</noscript>
    </div>

</div>
<script src="https://www.goodreads.com/review/grid_widget/106397951.My%20recent%20favs?cover_size=medium&hide_link=&hide_title=&num_books=20&order=d&shelf=read&sort=date_added&widget_id=1716234150" type="text/javascript" charset="utf-8"></script>

  `;

  return (
    <Layout>
      <div>
        <h2 className="section-header">
          <span>📚📚📚</span>
        </h2>
        <div className="card-section">
          <div dangerouslySetInnerHTML={{ __html: goodreadsWidget }} />
        </div>
      </div>
    </Layout>
  );
}

export default Update;